import React from "react";

import pokemon1 from "../../images/pokemon-1.jpg";
import pokemon2 from "../../images/pokemon-2.jpg";
import pokemon4 from "../../images/pokemon-4.jpg";

import arrowRight from "../../images/icons/arrow-r.svg";
import closeBtn from '../../images/icons/x.svg';
import clock from "../../images/icons/history.svg";
import HistoryBattlsItem from "./HistoryBattlsItem";
import "./HistoryBattls.css";

/*  Доп.библиотеки   */
import $ from "jquery";
import {MainFunc} from "../kenshi_config/kenshi_functions";

const HistoryBattls = function () {
  const [HistoryListOpen, SetHistoryListOpen] = React.useState('');
  const [HistoryListHeight, SetHistoryListHeight] = React.useState('400px');
  const [HistoryListActive, SetHistoryListActive] = React.useState(false);

  function h_Click(e) {
    e = $('.history-top');

    SetHistoryListActive(true);
    SetHistoryListOpen(!HistoryListOpen);

    if (HistoryListOpen) {
      $('.trainer-item .content__inner').css('gap', '');

      $('section.trainer-item.single-trainer').css('margin-bottom', '80px')
      MainFunc.trainer_or_pokemons_open_lists(0, 2);
    } else {
      if(window.innerWidth > 768) {
        var content_h = MainFunc.trainer_or_pokemons_open_lists(0, 1);

        SetHistoryListHeight(content_h);
      }
      return false

    }

  }

  var class_open = '';

  if (!HistoryListActive){
    class_open = 'feature-empty '
  }

  if (!HistoryListOpen){
    class_open += "trainer-item__history";
  }else{
    class_open += "trainer-item__history list-open";
  }

  return (
    <>
      {/* если нет истории вешаем дополнительно класс feature-empty */}
      <div className={class_open}>
        <a className="history-top" onClick={h_Click}>
          <img src={clock} width="24" height="24" alt="история битв" />
          <div className="history-info">
            История битв <span className="total-count history-info_count">0</span>
          </div>
          <button id="dropdown" className={!HistoryListOpen ? "btn-down btn-reset" : "btn-down btn-reset down"}>
            <img src={!HistoryListOpen ? arrowRight : closeBtn } width="26" height="26" alt="история битв" />
          </button>
        </a>

        <div className={HistoryListOpen ? 'top-mobile' : 'top-mobile_hidden'}>
          <button id="dropdown" className='top-mobile__btn' onClick={h_Click}></button>
          <div className="top-mobile__title">История битв</div>
        </div>

        <div style={{'height': HistoryListHeight+'px'}} className={!HistoryListOpen ? "history__list k_trassing_repeater_battles" : "history__list list-open k_trassing_repeater_battles"}>
          <HistoryBattlsItem
            id="1007"
            attack="4"
            icon={pokemon1}
            pokemonName="Пикачу"
            enimyId="98"
            enimyAttack="3"
            enimyIcon={pokemon4}
            enimyPokemonName="Райчу"
            defeat={true}
          />
        </div>
      </div>
    </>
  );
};

export default HistoryBattls;
