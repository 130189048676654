import { useNavigate } from "react-router-dom";
import "./ShopList.css";

import Cookies from 'js-cookie';
import site_config from "../../kenshi_config/kenshi_config"
import $ from "jquery";
function ShopList() {
    const shopList = [];
    $.ajax({
        url: site_config.new_api + '/debug_menu/get_avatars',
        type: 'GET',
        dataType: 'json',
        async: false,
        success: function(data){
            data = data.data;

            for (var i = 0; i < data.length; i++){
                var class_this = 'shop__item';
                if('https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data[i].id).padStart(2, '0')+'.png' === Cookies.get('user_image')){
                    class_this += ' feature-empty';
                }else{
                    class_this += ' available';
                }

                shopList.push({
                    img: 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data[i].id).padStart(2, '0')+'.png',
                    name: "Аватар тренера № "+data[i].id,
                    price: data[i].price+' ₽',
                    link: data[i].id,
                    class: class_this
                });

                Cookies.set('order_type', 'avatar', { expires: 365 });
            }
        }, error: function (){
            $('.k_500').fadeIn();
            $('.k_trassing_template_all').css('display', 'none');
            $('.k_hide_block').css('display', 'none');
        }
    });

    const navigate = useNavigate();
    return (
        <section className="shop">
            <ul className="shop__list">
                {shopList.map((item, index) => {
                    return (
                        <li className={item.class} key={index}>
                            <img
                                className="shop__img"
                                src={item.img}
                                alt="Аватар тренера № {item.id}"
                            />
                            <h2 className="shop__title">{item.name}</h2>
                            <p className="shop__price">{item.price}</p>
                            <button
                                onClick={() => navigate("/payment/"+item.link)}
                                className="shop__button"
                            >
                                Купить
                            </button>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
}
export default ShopList;
