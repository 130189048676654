import React from "react";
import Level from "../../Level/Level";
import Copy from "../../Copy/Copy";

import arrowRight from "../../../images/icons/arrow-r.svg";
import closeBtn from '../../../images/icons/x.svg';
import pokemons from "../../../images/pokemons.svg";
import pokemon1 from "../../../images/pokemon-1.jpg";
import pokemon4 from "../../../images/pokemon-4.jpg";
import pokemon7 from "../../../images/pokemon-7.jpg";

import imageBall from "../../../images/icons/ball.png";
import imageCoin from "../../../images/icons/gold_ball.png";
import imageScull from "../../../images/skull.svg";
import imageCup from "../../../images/icons/trophy.png";

import "./TrenerPokemons.css";
import $ from "jquery";
import {MainFunc} from "../../kenshi_config/kenshi_functions";

const TrenerPokemons = ({ select, setSelect }) => {
  const [PokemonListOpen, SetPokemonListOpen] = React.useState(select);
  const [PokemonListHeight, SetPokemonListHeight] = React.useState('400px');
  const [PokemonListActive, SetPokemonListActive] = React.useState(false);

  const handleClick = (e) => {
    e = $('.pokemons-top');

    SetPokemonListActive(true);
    SetPokemonListOpen(!PokemonListOpen);
    setSelect(!select);

    if (PokemonListOpen) {
      $('.trainer-item .content__inner').css('gap', '');

      $('section.trainer-item.single-trainer').css('margin-bottom', '80px')
      MainFunc.trainer_or_pokemons_open_lists(2);
    } else {
      if(window.innerWidth > 768) {
        var content_h = MainFunc.trainer_or_pokemons_open_lists(1);

        SetPokemonListHeight(content_h);
      }

      return false
    }
  };

  var class_open = '';

  if (!PokemonListActive){
    class_open = 'feature-empty '
  }

  if (!PokemonListOpen){
    class_open += "trainer-item__pokemons k_cart_trainer_pokemons_history_div";
  }else{
    class_open += "trainer-item__pokemons list-open k_cart_trainer_pokemons_history_div";
  }

  return (
    <>
      {/* если нет покемонов вешаем дополнительно класс feature-empty */}

      <div
        className={class_open}
      >
        <a className="pokemons-top" onClick={handleClick}>
          <img className='pokemons-top__img' src={pokemons} width="24" height="24" alt="" />
          <div className="pokemons-info">
            Покемоны <span className="total-count k_cart_trainer_pokemons_history">0</span>
          </div>

          {/* вешаем на кнопку disabled если нет покемонов */}
          <button
            id="dropdown"
            className={
              !PokemonListOpen
                ? "btn-down btn-reset"
                : "btn-down btn-reset down"
            }
          >
            <img src={!PokemonListOpen ? arrowRight: closeBtn} width="26" height="26" alt="" />
          </button>
        </a>

        <div className={PokemonListOpen ? 'top-mobile' : 'top-mobile_hidden'}>
          <button id="dropdown" className='top-mobile__btn' onClick={handleClick}></button>
          <div className="top-mobile__title">Покемоны</div>
        </div>

        <div
          className={
            PokemonListOpen
              ? `pokemons-dropdown__list list-open k_cart_trainer_pokemons_history_div_drop`
              : `pokemons-dropdown__list k_cart_trainer_pokemons_history_div_drop`
          }
          style={{'height': PokemonListHeight+'px'}}
        >

          <div className="pokemons-dropdown__item_none pokemons-dropdown__item" style={{display:'none'}}>
            <div className="pokemons__link pokemons__link_h">
              <img className="pokemons-dropdown__img" src={pokemon4} alt="" />
              <div className="pokemons-dropdown__content">
                <div className="pokemons-dropdown__title">Кроконав</div>
                <div className="k_pokemon_flex_att_icon">
                  <div
                    className="title-single__icon k_title_icon_status_pok"
                    data-icon='true'
                    data-kill='false'
                  >
                    <img
                      src={imageBall}
                      className="title-single__img"
                      alt="иконки заголовка"
                    />
                  </div>
                  <Level level="5" name="Атака" />
                </div>
                <Copy copyCount="1001" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrenerPokemons;
